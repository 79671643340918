var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "level" }, [
      _c("div", { staticClass: "level-left", staticStyle: { width: "45%" } }, [
        _c("div", { staticClass: "field", staticStyle: { width: "100%" } }, [
          _c("label", { staticClass: "label is-size-7" }, [_vm._v("Users")]),
          _c("div", { staticClass: "control" }, [
            _c(
              "div",
              {
                staticClass: "select is-multiple",
                staticStyle: { width: "100%" },
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedUnassignedClients,
                        expression: "selectedUnassignedClients",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "lstUnassignedClients",
                      multiple: "",
                      size: _vm.listSize,
                    },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedUnassignedClients = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.unassignedClients, function (id) {
                    return _c("option", { key: id, domProps: { value: id } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.clientsMap.get(id)
                              ? _vm.clientsMap.get(id).name
                              : null
                          ) +
                          " (" +
                          _vm._s(
                            _vm.clientsMap.get(id)
                              ? _vm.clientsMap.get(id).email
                              : null
                          ) +
                          ") "
                      ),
                    ])
                  }),
                  0
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "level-center", staticStyle: { width: "10%" } },
        [
          _c(
            "div",
            { staticClass: "has-text-centered", attrs: { id: "buttonblock" } },
            [
              _c("div", { staticClass: "block" }, [
                _c(
                  "a",
                  {
                    staticClass: "button is-accent is-outlined",
                    on: { click: _vm.moveRight },
                  },
                  [_c("i", { staticClass: "fas fa-caret-right fa-2x" })]
                ),
              ]),
              _c("div", { staticClass: "block" }, [
                _c(
                  "a",
                  {
                    staticClass: "button is-accent is-outlined",
                    on: { click: _vm.moveLeft },
                  },
                  [_c("i", { staticClass: "fas fa-caret-left fa-2x" })]
                ),
              ]),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "level-right", staticStyle: { width: "45%" } }, [
        _c("div", { staticClass: "field", staticStyle: { width: "100%" } }, [
          _vm._m(0),
          _c("div", { staticClass: "control" }, [
            _c(
              "div",
              {
                staticClass: "select is-multiple",
                staticStyle: { width: "100%" },
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedAssignedClients,
                        expression: "selectedAssignedClients",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "lstSendToUsers",
                      multiple: "",
                      size: _vm.listSize,
                    },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedAssignedClients = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _vm.input.assignedClients.length === 0
                      ? _c("option", { attrs: { disabled: "" } }, [
                          _vm._v(" Select one or more clients "),
                        ])
                      : _vm._e(),
                    _vm._l(_vm.input.assignedClients, function (id) {
                      return _c(
                        "option",
                        { key: id, domProps: { value: id } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.clientsMap.get(id)
                                  ? _vm.clientsMap.get(id).name
                                  : null
                              ) +
                              " (" +
                              _vm._s(
                                _vm.clientsMap.get(id)
                                  ? _vm.clientsMap.get(id).email
                                  : null
                              ) +
                              ") "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Allowed to Complete Questionnaire "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }